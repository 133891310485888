<template>
  <el-dialog
    class="eval-modal"
    title="回复评价"
    :visible="value"
    width="600px"
    destroy-on-close
    :before-close="handleClose"
  >
    <div>
      <el-input
        type="textarea"
        :rows="8"
        placeholder="感谢您对小店的支持..."
        v-model="form.reply"
        maxlength="200"
        show-word-limit
      >
      </el-input>
      <div class="tip">仅可评论1次，发表后不可修改，请不要涉及买家隐私</div>
      <div>
        <el-upload
          class="uploader"
          list-type="picture-card"
          :limit="3"
          name="multipartFile"
          :action="$api.nb_common.newUpload"
          :on-success="uploadSuc"
          :on-remove="remove"
          accept="image/*"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="tip">最多上传三张图片</div>
    </div>

    <div slot="footer" class="tr">
      <el-button size="mini" @click="handleClose">取 消</el-button>
      <el-button :loading="saveLoad" size="mini" type="primary" @click="confirm"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {eval_reply} from '@/api/goods';
export default {
  props: {
    value: {
      default: false,
    },
    row: Object,
  },
  data() {
    return {
      saveLoad: false,
      form: {
        reply: "",
        replyImage: "",
      },
      replyArr: [],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.initData();
      }
    },
  },
  mounted() {},
  methods: {
    initData() {
      this.form = {
        reply: "",
        replyImage: "",
      };

      this.replyArr = [];
    },
    handleClose(done) {
      this.$emit("input", false);
      done && done();
    },
    uploadSuc(res) {
      if (res.code === 0) {
        this.replyArr.push(res.data.fileUrl);
      } else {
        this.$message.error(res.msg);
      }
    },
    remove(file) {
      let url = file.response.data.fileUrl;
      let index = this.replyArr.findIndex((e) => e === url);
      this.replyArr.splice(index, 1);
    },
    getPars() {
      return {
        id: this.row.id,
        reply: this.form.reply,
        replyImage: this.replyArr.join()
      }
    },
    confirm() {
      if (!this.form.reply) {
        this.$message.warning("请输入评价内容");
        return;
      }

      this.saveLoad = true;
      let pars = this.getPars();

      eval_reply(pars).then(res => {
        this.saveLoad = false;
        if(res.code === 0) {
          this.$message.success('回复成功');
          this.handleClose();
          this.$emit('update');
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.eval-modal {
  .tip {
    color: #999;
    font-size: 12px;
    margin: 10px;
  }
}
</style>