import goods from '../http/nb/goods';
import axios from '@/http/axios'

export function eval_list(data) {
    return axios.request({
        url: goods.eval.list,
        method: 'post',
        data: data
    })
}

export function eval_reply(data) {
    return axios.request({
        url: goods.eval.reply,
        method: 'post',
        data: data
    })
}