var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "eval-modal",
      attrs: {
        title: "回复评价",
        visible: _vm.value,
        width: "600px",
        "destroy-on-close": "",
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 8,
              placeholder: "感谢您对小店的支持...",
              maxlength: "200",
              "show-word-limit": "",
            },
            model: {
              value: _vm.form.reply,
              callback: function ($$v) {
                _vm.$set(_vm.form, "reply", $$v)
              },
              expression: "form.reply",
            },
          }),
          _c("div", { staticClass: "tip" }, [
            _vm._v("仅可评论1次，发表后不可修改，请不要涉及买家隐私"),
          ]),
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  staticClass: "uploader",
                  attrs: {
                    "list-type": "picture-card",
                    limit: 3,
                    name: "multipartFile",
                    action: _vm.$api.nb_common.newUpload,
                    "on-success": _vm.uploadSuc,
                    "on-remove": _vm.remove,
                    accept: "image/*",
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "tip" }, [_vm._v("最多上传三张图片")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tr", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.saveLoad, size: "mini", type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }