<template>
  <div class="search">
    <el-input
      class="input"
      size="mini"
      clearable
      v-model="form.orderNo"
      placeholder="订单号"
    ></el-input>
    <el-date-picker
      v-model="form.startTime"
      size="mini"
      clearable
      value-format="timestamp"
      class="date"
      type="date"
      placeholder="开始时间"
    >
    </el-date-picker>
    <el-date-picker
      v-model="form.endTime"
      class="date"
      clearable
      size="mini"
      value-format="timestamp"
      type="date"
      placeholder="结束时间"
    >
    </el-date-picker>
    <el-select
      size="mini"
      class="select"
      clearable
      v-model="form.level"
      placeholder="评价等级"
    >
      <el-option label="全部" :value="0" />
      <el-option label="好评" :value="1" />
      <el-option label="中评" :value="2" />
      <el-option label="差评" :value="3" />
    </el-select>
    <el-input
      class="input"
      size="mini"
      v-model="form.userNick"
      placeholder="用户昵称"
      clearable
    ></el-input>
    <el-input
      class="input"
      size="mini"
      v-model="form.userMobile"
      clearable
      placeholder="手机号"
    ></el-input>
    <el-input
      class="input"
      size="mini"
      v-model="form.goodsId"
      clearable
      placeholder="关联商品ID"
    ></el-input>
    <el-select
      size="mini"
      clearable
      class="select"
      v-model="form.replyType"
      placeholder="评价类型"
    >
      <el-option label="全部" :value="0" />
      <el-option label="有回复" :value="1" />
      <el-option label="无回复" :value="2" />
    </el-select>

    <div class="btn-group">
      <el-button size="mini" @click="reset">重置</el-button>
      <el-button size="mini" @click="search" type="primary">搜索</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.form = {
        endTime: "",
        goodsId: "",
        level: "",
        orderNo: "",
        replyType: "",
        startTime: "",
        userMobile: "",
        userNick: "",
      };
    },
    reset() {
      this.init();
      this.$emit("search", this.form);
    },
    search() {
      this.$emit("search", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  .input,
  .date,
  .select {
    width: 200px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .btn-group {
    display: inline-block;
    margin-top: 10px;
  }
}
</style>