<template>
  <div class="eval">
    <div class="tb-box bgf">
      <Search
        class="margin-b20"
        @search="doSearch"
      />

      <el-table
        v-loading="tbLoad"
        :height="getViewHeight() - 270"
        :data="tbList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="orderNo"
          label="订单ID"
          align="center"
        />
        <el-table-column
          label="商品"
          min-width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <div class="flex-m">
              <img
                class="good-img"
                :src="row.goodsImage.split(',')[0]"
                alt=""
              />
              <div class="good">
                <div class="name">
                  {{ row.goodsName }}
                </div>
                <div class="price">
                  ￥{{ row.goodsPrice }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="comment"
          label="评价内容"
          align="center"
          min-width="200"
        >
          <template slot-scope="{ row }">
            <template v-if="row.comment">
              <div class="comment-text">
                {{ row.comment }}
              </div>
              <div>
                <el-image
                  v-for="(item,i) in row.imageIds"
                  :key="i"
                  style="width: 80px; height: 80px"
                  :src="item"
                  :preview-src-list="row.imageIds"
                  fit="cover"
                />
              </div>
              <div class="comment-text">
                {{ row.createTime }}
              </div>
            </template>
            <template v-if="row.reply">
              <div class="comment-text">
                <span style="color: red">商家回复:</span>
                <span>{{ row.reply }}</span>
              </div>
              <div v-if="row.replyImage">
                <el-image
                  v-for="(item,i) in row.replyImage.split()"
                  :key="i"
                  style="width: 80px; height: 80px"
                  :src="item"
                  :preview-src-list="row.replyImage.split()"
                  fit="cover"
                />
              </div>
              <div class="comment-text">
                {{ row.replyTime }}
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="评价等级"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ levelDic[row.level] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="userNick"
          label="客户"
          align="center"
        />
        <el-table-column
          prop="userMobile"
          label="手机号"
          align="center"
        />
        <el-table-column
          width="120"
          label="操作"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              :disabled="row.isReply == 1"
              @click="replyEval(row)"
            >
              {{ row.isReply ? "已回复评价" : "回复评价" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :page-size="page.pageSize"
        :total="totalRecords"
        :current-page="page.currentPage"
        @current-change="changePage"
        @size-change="changePageSize"
      />
    </div>

    <EvalModal
      v-model="showEvalModal"
      :row="currentRow"
      @update="getList"
    />
  </div>
</template>

<script>
import Search from './components/Search.vue';
import EvalModal from './components/EvalModal.vue';
import { eval_list } from '@/api/goods';
import { dateFormat } from '@/utils/utils';

export default {
  components: {
    Search,
    EvalModal,
  },
  data() {
    return {
      currentRow: null,
      showEvalModal: false,
      tbLoad: false,
      totalRecords: 0,
      tbList: [],
      queryForm: {},
      levelDic: {
        1: '好评',
        2: '中评',
        3: '差评',
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.getList();
    },
    doSearch(form) {
      this.queryForm = form;
      this.page.currentPage = 1;
      this.getList();
    },
    getPars() {
      const pars = {
        ...this.queryForm,
        ...this.page,
      };

      return pars;
    },
    getList() {
      this.tbLoad = true;
      const pars = this.getPars();

      eval_list(pars).then((res) => {
        this.tbLoad = false;
        if (res.code === 0) {
          res.data.records.forEach((e) => {
            e.createTime = dateFormat(+e.createTime, 'yyyy-MM-dd hh:ss:mm');
            e.replyTime = dateFormat(+e.replyTime, 'yyyy-MM-dd hh:ss:mm');
          });
          this.tbList = res.data.records;
          this.totalRecords = +res.data.total;
        }
      });
    },
    replyEval(row) {
      this.currentRow = row;
      this.showEvalModal = true;
    },
    changePage(p) {
      this.page.currentPage = p;
      this.getList();
    },
    changePageSize(s) {
      this.page.pageSize = s;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.eval {
  .nav {
    border-radius: 6px;
    padding: 0 10px 10px;
    margin-bottom: 10px;
  }
  .tb-box {
    padding: 10px;
    border-radius: 6px;
    .good-img {
      width: 80px;
      margin-right: 10px;
    }
    .good {
      .name {
        font-size: 13px;
      }
      .price {
        font-size: 13px;
        color: red;
      }
    }
    .comment-text {
      font-size: 13px;
      color: #444;
      line-height: 1.8;
    }
  }
}
</style>
